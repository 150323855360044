<template>
  <div style="background: #FAFAFA;">
    <Myhead :nav="5"></Myhead>
    <div style="height: 360px; margin-top: -70.5px">
      <img class="imgStyle" src="../assets/img/reportQuery/bg.jpg" alt/>
    </div>
    <div class="container">
      <div class="title">{{Language.reportQuery.report_query}}</div>
      <div class="form">
        <div class="label">{{Language.reportQuery.Certificate_Number}}</div>
        <input v-model="BillNum" :placeholder="Language.reportQuery.Enter_certificate_number"/>
      </div>
      <div class="form">
        <div class="label">{{Language.reportQuery.Customer_Name}}</div>
        <input v-model="VendorName" :placeholder="Language.reportQuery.Enter_Customer_Name"/>
      </div>
      <!--      <div class="form">-->
      <!--        <div class="label">验证码</div>-->
      <!--        <input placeholder="请输入验证码"/>-->
      <!--      </div>-->
      <div>
        <div class="btn1" @click="getReport">{{Language.reportQuery.query}}</div>
      </div>
    </div>

    <div class="file" id="dataDownload1" v-if="fileList && fileList.length > 0">
      <div style="height: 550px;overflow: auto">
        <div class="file-item" v-for="(item, index) in fileList" :key="index">
          <!--          <div class="item-img">-->
          <!--            <img class="imgStyle" src="../assets/img/dataDownload/PDF.png" alt/>-->
          <!--          </div>-->
          <div class="item-introduce">{{ item.AnnexName }} ------- ({{ item.FileSizeStr }})</div>
          <div class="btn-contactUs2" @click="toopen(item)">{{Language.reportQuery.download}}</div>
        </div>
      </div>
    </div>


    <div class="content5">
      <div class="content5-container">
        <div>
          <div class="content5-title">{{Language.reportQuery.message1}}</div>
          <div class="content5-text">{{Language.reportQuery.message2}}</div>
        </div>
        <div class="btn-contactUs" @click="tocontactUs">{{Language.reportQuery.Appointment_demonstration}}</div>
      </div>
    </div>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";

export default {
  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components: {
    Myhead,
    Myfoot
  },
  data() {
    return {
      BillNum: "",
      VendorName: "",
      fileList: []
    }
  },
  activated() {
  },
  mounted() {
  },
  methods: {
    tocontactUs() {
      this.$router.push("/contact")
    },
    getReport() {
      var data = {
        View: 'VM_Report',
        BillNum: this.BillNum,
        VendorName: this.VendorName
      }
      this.$get("/m/nl/view", data).then(res => {
        console.log(res)
        if (res.data.data) {
          this.$get("/m/nl/tablefunc", {
            tableName: "FTA_Bill_AnnexFile",
            inParas: '1,1,' + res.data.data[0].FClassID + ',' + res.data.data[0].BillID + ',' + res.data.data[0].FTypeID,
          }).then(res1 => {
            console.log(res1)
            this.fileList = res1.data.data
          })
        } else {
          this.$toast("请输入正确的报告号和客户名称")
        }
      })
    },
    toopen(e) {
      // if(e){
      var path = e.AnnexDir
      var FileName = e.FileName
      path = path.replace(/\\/g, "/")
      window.open('http://file.qc-plus.cn:8090/' + path + FileName)
      // }else {
      //   this.$toast("暂无pdf")
      // }

    },
  }
}
</script>
<style lang="scss" scoped>
.imgStyle{
  object-fit: cover;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 120px 0;


  .title {
    color: var(--title-color);
    font-size: 42px;
    text-align: center;
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
    padding: 0px 20px;

    .label {
      color: var(--title-color);
      width: 200px;
      text-align: right;
      margin-right: 30px;
    }

    input {
      width: calc(100% - 246px);
      height: 56px;
      border: 1px solid #ffffff;
      border-radius: 8px;
      padding-left: 16px;
      font-size: 18px;
      color: var(--title-color);
    }

    input::placeholder {
      font-size: 14px;
      color: #999999;
    }

    input:focus {
      outline: 1px solid var(--theme-color1);
      border-color: var(--theme-color1);
    }
  }

  .btn1 {
    margin: 120px auto 0;
  }
}

.content5 {
  height: 240px;
  background-image: url("../assets/img/qualityStack/banner4.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .content5-container {
    max-width: 1200px;
    height: 240px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;

    .content5-title {
      font-size: 42px;
      font-weight: bold;
      line-height: 58px;
      margin-bottom: 16px;
    }

    .content5-text {
      width: 745px;
      font-size: 24px;
      line-height: 38px;
    }
  }
}


.file {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 100px;

  .file-title {
    font-size: 42px;
    color: var(--title-color);
    padding-bottom: 40px;
    border-bottom: 2px solid #e6e5e5;
  }

  .file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1px solid #e6e5e5;

    .item-img {
      width: 72px;
      height: 72px;
    }

    .item-introduce {
      width: 890px;
      color: var(--text-color);
      line-height: 28px;
    }
  }
}
@media (min-width: 0px) and (max-width: 1920px){
  .content5{
    background-size: auto 100%;
  }
}
@media (min-width: 0px) and (max-width: 1200px){
  .container{
    width: 100%;
    .form{
      .label{
        width: 130px;
      }
      input{
        width: calc(100% - 178px);
      }
    }
  }

  .content5{
    .content5-container{
      padding: 0px 20px;
      flex-wrap: wrap;
      width: 100%;
      .content5-title{
        font-size: 30px;
        line-height:30px;
      }
      .content5-text{
        font-size: 16px;
        line-height: 25px;
        width: 100%;
      }
    }
  }
}

</style>
